.title {
    color: var(--text-base);
    font-size: 30px;
    font-weight: 600;
}
.price {
    display: flex;
    align-items: center;
    color: var(--moss-green);
    font-size: 23px;
}

.unorderedList {
    list-style-type: disc;
    color: var(--text-description);
}
.listItem {
    margin-top: 10px;
}
.strong {
    color: var(--text-base);
}

/* .title {
    color: var(--text-base);
    font-size: 30px;
    font-weight: 600;
}
.price {
    display: flex;
    align-items: center;
    color: var(--moss-green);
    font-size: 23px;
}
.buyButton {
    color: var(--brand-button-bg-color);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 50px;
    border-color: var(--brand-button-bg-color);
    border-width: 1.5px;
    margin-left: 20px;
    padding: 8px 24px 8px 24px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}
.buyButton:hover {
    color: #ffffff;
    background-color: var(--brand-button-bg-color);
}

.unorderedList {
    list-style-type: disc;
    color: var(--text-description);
}
.listItem {
    margin-top: 10px;
}
.strong {
    color: var(--text-base);
} */

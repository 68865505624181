@tailwind base;
/* @tailwind components; */
@tailwind utilities;

:root {
    --primary: #fff;
    --primary-2: #f1f3f5;
    --secondary: #000;
    --secondary-2: #111;
    --selection: var(--cyan);
    --text-base: #323232;
    --text-primary: #323232;
    --text-secondary: #fff;
    --text-description: #777777;
    --hover: rgba(0, 0, 0, 0.075);
    --hover-1: rgba(0, 0, 0, 0.15);
    --hover-2: rgba(0, 0, 0, 0.25);
    --cyan: #22b8cf;
    --green: #37b679;
    --moss-green: #8dbf8b;
    --red: #da3c3c;
    --purple: #f81ce5;
    --blue: #0070f3;
    --gray: #999999;
    --pink: #ff0080;
    --pink-light: #ff379c;
    --magenta: #eb367f;
    --violet: #7928ca;
    --violet-dark: #4c2889;
    --accent-0: #fff;
    --accent-1: #fafafa;
    --accent-2: #eaeaea;
    --accent-3: #999;
    --accent-4: #888;
    --accent-5: #666;
    --accent-6: #444;
    --accent-7: #333;
    --accent-8: #111;
    --accent-9: #000;
    --font-sans: Gilroy, sans-serif;
    --brand-color: #005d8e;
    --brand-sub-color: #fc8037;
    --brand-text-color: #005d8e;
    --brand-button-bg-color: #005d8e;
    --brand-button-text-color: #fff;
    --brand-linear-color: #10b5ec;
    --anker-color: #00a7e1;
    --anker-linear-color: #10b5ec;
    --eufy-color: #005d8e;
    --eufy-nav-color: #005d8e;
    --eufy-logo-color: #999;
    --soundcore-color: #00a9df;
    --nebula-color: #e42d46;
    --fw-live-helper-bottom: 160px;
}

/* --primary: #005d8e;
--primary-hover: #669ebb;
--brand-color: #005d8e;
--brand-text-color: #005d8e;
--text-primary: #333;
--text-color: #333;
--sub-text-color: #666;
--success-color: #34c759;
--success-warn: #ffc24d;
--success-error: #ff4d4d; */

/* :root {
    --primary: #005d8e;
    --primary-hover: #669ebb;
    --brand-color: #005d8e;
    --brand-text-color: #005d8e;
    --text-primary: #333;
    --text-color: #333;
    --sub-text-color: #666;
    --success-color: #34c759;
    --success-warn: #ffc24d;
    --success-error: #ff4d4d;
} */
/* Menu 323232 | Body text color 33333 */
/* font-family: '__Gilroy_7e22cc', '__Gilroy_Fallback_7e22cc', -apple-system, system-ui, BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif; */

body {
    line-height: 1.5;
    -webkit-text-size-adjust: 100%;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    font-family: Georgian, "__Gilroy_7e22cc", -apple-system, system-ui,
        BlinkMacSystemFont, "Helvetica Neue", "Helvetica", sans-serif;
    font-feature-settings: normal;
    font-variation-settings: normal;
}

a {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.flex {
    display: flex;
}

[inert] {
    pointer-events: none;
    cursor: default;
}

[inert],
[inert] * {
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
}

/* .affirmBrand {
    --color-primary-lightest-rgb: 230, 230, 252;
    --color-primary-lightest: #e6e6fc;
    --color-primary-light-rgb: 168, 169, 252;
    --color-primary-light: #a8a9fc;
    --color-primary-base-rgb: 74, 74, 244;
    --color-primary-base: #4a4af4;
    --color-primary-dark-rgb: 47, 47, 193;
    --color-primary-dark: #2f2fc1;
    --color-primary-darkest-rgb: 33, 33, 137;
    --color-primary-darkest: #212189;
    --color-warning-lightest-rgb: 252, 249, 232;
    --color-warning-lightest: #fcf9e8;
    --color-warning-light-rgb: 255, 233, 143;
    --color-warning-light: #ffe98f;
    --color-warning-base-rgb: 255, 202, 97;
    --color-warning-base: #ffca61;
    --color-warning-dark-rgb: 232, 140, 49;
    --color-warning-dark: #e88c31;
    --color-warning-darkest-rgb: 219, 100, 20;
    --color-warning-darkest: #db6414;
    --color-destructive-lightest-rgb: 247, 227, 226;
    --color-destructive-lightest: #f7e3e2;
    --color-destructive-light-rgb: 254, 170, 151;
    --color-destructive-light: #feaa97;
    --color-destructive-base-rgb: 255, 109, 80;
    --color-destructive-base: #ff6d50;
    --color-destructive-dark-rgb: 211, 67, 51;
    --color-destructive-dark: #d34333;
    --color-destructive-darkest-rgb: 147, 52, 47;
    --color-destructive-darkest: #93342f;
    --color-success-lightest-rgb: 232, 249, 239;
    --color-success-lightest: #e8f9ef;
    --color-success-light-rgb: 151, 232, 184;
    --color-success-light: #97e8b8;
    --color-success-base-rgb: 42, 188, 122;
    --color-success-base: #2abc7a;
    --color-success-dark-rgb: 10, 137, 76;
    --color-success-dark: #0a894c;
    --color-success-darkest-rgb: 16, 84, 50;
    --color-success-darkest: #105432;
    --color-ui-colors-background-rgb: 255, 255, 255;
    --color-ui-colors-background: #ffffff;
    --color-ui-colors-overlay-rgb: 16, 24, 32, 0.65;
    --color-ui-colors-overlay: rgba(16, 24, 32, 0.65);
    --color-ui-colors-shadow-rgb: 16, 24, 32, 0.16;
    --color-ui-colors-shadow: rgba(16, 24, 32, 0.16);
    --color-ui-colors-background-inverse-rgb: 56, 61, 73;
    --color-ui-colors-background-inverse: #383d49;
    --color-ui-colors-background-neutral-1-rgb: 244, 243, 242;
    --color-ui-colors-background-neutral-1: #f4f3f2;
    --color-ui-colors-background-neutral-2-rgb: 240, 245, 247;
    --color-ui-colors-background-neutral-2: #f0f5f7;
    --color-ui-colors-border-neutral-1-rgb: 237, 233, 230;
    --color-ui-colors-border-neutral-1: #ede9e6;
    --color-ui-colors-border-neutral-2-rgb: 211, 227, 234;
    --color-ui-colors-border-neutral-2: #d3e3ea;
    --color-ui-colors-divider-rgb: 212, 214, 215;
    --color-ui-colors-divider: #d4d6d7;
    --color-ui-colors-border-rgb: 144, 146, 147;
    --color-ui-colors-border: #909293;
    --color-ui-colors-text-100-rgb: 16, 24, 32;
    --color-ui-colors-text-100: #101820;
    --color-ui-colors-text-80-rgb: 56, 61, 73;
    --color-ui-colors-text-80: #383d49;
    --color-ui-colors-text-60-rgb: 109, 110, 113;
    --color-ui-colors-text-60: #6d6e71;
    --color-ui-colors-caption-rgb: 16, 24, 32;
    --color-ui-colors-caption: #101820;
    --color-greyscale-white-rgb: 255, 255, 255;
    --color-greyscale-white: #ffffff;
    --color-greyscale-black-10-rgb: 237, 233, 230;
    --color-greyscale-black-10: #ede9e6;
    --color-greyscale-black-20-rgb: 212, 214, 215;
    --color-greyscale-black-20: #d4d6d7;
    --color-greyscale-black-40-rgb: 144, 146, 147;
    --color-greyscale-black-40: #909293;
    --color-greyscale-black-60-rgb: 109, 110, 113;
    --color-greyscale-black-60: #6d6e71;
    --color-greyscale-black-80-rgb: 56, 61, 73;
    --color-greyscale-black-80: #383d49;
    --color-greyscale-black-100-rgb: 16, 24, 32;
    --color-greyscale-black-100: #101820;
    --color-greyscale-black-rgb: 0, 0, 0;
    --color-greyscale-black: #000;
    --font-family-display: "Mangueira", sans-serif;
    --font-family-body: "Calibre", sans-serif;
    --font-family-monospace: "Alma Mono", Courier, monospace;
    --font-size-xxxl: 72px;
    --font-size-xxl: 40px;
    --font-size-xl: 32px;
    --font-size-l: 28px;
    --font-size-medium: 24px;
    --font-size-base: 20px;
    --font-size-sm: 18px;
    --font-size-xs: 16px;
    --font-size-xxs: 14px;
    --font-size-xxxs: 13px;
    --line-height-xxxl: 86px;
    --line-height-xxl: 44px;
    --line-height-xl: 36px;
    --line-height-l: 32px;
    --line-height-medium: 30px;
    --line-height-base: 24px;
    --line-height-sm: 26px;
    --line-height-xs: 24px;
    --line-height-xxs: 20px;
    --letter-spacing-1: 0.25px;
    --letter-spacing-2: 0.5px;
    --letter-spacing-neg-2: -0.5px;
    --letter-spacing-neg-1: -0.15px;
    --letter-spacing-normal: 0px;
    --font-weight-100: 100;
    --font-weight-200: 200;
    --font-weight-300: 300;
    --font-weight-400: 400;
    --font-weight-500: 500;
    --font-weight-600: 600;
    --font-weight-700: 700;
    --font-weight-800: 800;
    --font-weight-900: 900;
} */

/* -webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale; */
/* "DejaVuSans", -apple-system, BlinkMacSystemFont, "Segoe UI",  "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif !important; */

/* --primary: #fff;
    --primary-2: #f1f3f5;
    --secondary: #000;
    --secondary-2: #111;
    --selection: var(--cyan);
    --text-base: #323232;
    --text-primary: #323232;
    --text-secondary: #fff;
    --hover: rgba(0,0,0,.075);
    --hover-1: rgba(0,0,0,.15);
    --hover-2: rgba(0,0,0,.25);
    --cyan: #22b8cf;
    --green: #37b679;
    --red: #da3c3c;
    --purple: #f81ce5;
    --blue: #0070f3;
    --pink: #ff0080;
    --pink-light: #ff379c;
    --magenta: #eb367f;
    --violet: #7928ca;
    --violet-dark: #4c2889;
    --accent-0: #fff;
    --accent-1: #fafafa;
    --accent-2: #eaeaea;
    --accent-3: #999;
    --accent-4: #888;
    --accent-5: #666;
    --accent-6: #444;
    --accent-7: #333;
    --accent-8: #111;
    --accent-9: #000;
    --font-sans: Gilroy, sans-serif;
    --brand-color: #005d8e;
    --brand-sub-color: #fc8037;
    --brand-text-color: #005d8e;
    --brand-button-bg-color: #005d8e;
    --brand-button-text-color: #fff;
    --brand-linear-color: #10b5ec;
    --anker-color: #00a7e1;
    --anker-linear-color: #10b5ec;
    --eufy-color: #005d8e;
    --eufy-nav-color: #005d8e;
    --eufy-logo-color: #999;
    --soundcore-color: #00a9df;
    --nebula-color: #e42d46; */

.brand-button {
    color: var(--brand-button-bg-color);
    font-size: 16px;
    font-weight: 600;
    text-decoration: none;
    border-radius: 50px;
    border-color: var(--brand-button-bg-color);
    border-width: 1.5px;
    /* margin-left: 20px; */
    padding: 8px 24px 8px 24px;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out;
}
.brand-button:hover {
    color: #ffffff;
    background-color: var(--brand-button-bg-color);
}

.footer:before {
    content: "";
    -webkit-clip-path: ellipse(50% 100% at 50% 0);
    clip-path: ellipse(50% 100% at 50% 0);
    display: block;
    height: 4rem;
    width: 100%;
    background-color: #ffffff;
}
.footer {
    background-color: #f7f8f9;
    padding-bottom: 50px;
}
.content {
    margin-left: 10px;
}

.sectionTitle {
    margin-top: 10px;
    margin-bottom: 15px;
    font-size: 20px;
    font-weight: 600;
    line-height: 1.375;
    color: var(--text-primary);
}
.sectionBody {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
}
.sectionBody li {
    margin-bottom: 10px;
    min-width: 20%;
    width: auto;
    display: flex;
    color: var(--gray);
}
.icon {
    height: 24px;
    width: 24px;
    margin-right: 6px;
}
.iconPrimaryText a {
    font-size: 16px;
    font-weight: 500;
    color: var(--brand-color);
    text-decoration: none;
}
.iconPrimaryText a:hover {
    text-decoration: underline;
}

.secondaryText a {
    font-size: 16px;
    font-weight: 500;
    text-decoration: none;
    transition: color 0.3s;
}
.secondaryText a:hover {
    color: var(--accent-7);
}

.download {
    display: flex;
}
.appIcon {
    width: 110px;
}
.download div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    margin-left: 20px;
}

.downloadIcon {
    width: 150px;
}

.certifications {
    display: flex;
    gap: 20px;
    margin-top: 20px;
    /* justify-content: flex-end; */
}

.keywords {
    font-size: 10px;
    margin-top: 20px;
    color: #f1f1f1;
}

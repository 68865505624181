.title {
    text-align: center;
    font-size: 2.2rem;
    margin-bottom: 3rem;
}

.unorderedList {
    list-style-type: disc;
}
.listItem {
    margin-left: 15px;
    /* font-size: 18px; */
}
.listItemLarge {
    margin-left: 15px;
    font-size: 18px;
}
